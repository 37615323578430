import { Questionaire, TakePhotoType } from '../models/QEQuestion';

export const debugQuestionnaire: Questionaire = {
  href: 'https://api.manheim.com/questions/id/DEBUG-FORM-GUID',
  count: 5,
  items: [
    {
      id: '1',
      guid: 'single-quantity',
      text: 'single select with qty',
      buyerTranslation: 'Other issues',
      section: 'MECHANICAL',
      type: 'single',
      answers: [
        {
          value: 'pick a number',
          chooseQuantity: true,
          connotation: -1,
          metaData: {
            takePhoto: {
              type: TakePhotoType.Damage,
              title: 'qty test',
              description: 'test'
            }
          }
        },
        {
          value: 'pick another number',
          connotation: -1,
          chooseQuantity: true,
          metaData: {
            takePhoto: {
              type: TakePhotoType.Damage,
              title: 'qty test2',
              description: 'test2'
            }
          }
        },
        {
          value: 'No',
          connotation: -1,
          metaData: {
            takePhoto: {
              type: TakePhotoType.Damage,
              title: 'no',
              description: 'nope'
            }
          }
        }
      ]
    },

    {
      id: '12',
      guid: 'CHARGING-CABLE-2',
      text: 'single select with regular answers',
      buyerTranslation: 'Other issues',
      section: 'MECHANICAL',
      type: 'multi',
      tags: ['engineType:electric'],
      answers: [
        {
          value: 'Yes',
          connotation: 0,
          metaData: {
            takePhoto: {
              type: TakePhotoType.Damage,
              title: 'cable',
              description: 'Front Hood Large Dent'
            }
          },
          answerGroups: ['A']
        },
        {
          value: 'maybe',
          connotation: 0,
          metaData: {
            takePhoto: {
              type: TakePhotoType.Damage,
              title: 'maybe',
              description: 'Front Hood Large Dent'
            }
          },
          answerGroups: ['B']
        },
        {
          value: 'No',
          connotation: -1
        }
      ]
    },
    {
      id: '11',
      guid: 'OTHER-ISSUES',
      text: 'What other issues does the vehicle have?',
      buyerTranslation: 'Other issues',
      section: 'MECHANICAL',
      type: 'multi',
      answers: [
        {
          value: 'OBD2 Codes',
          connotation: -1,
          showNotes: true,
          notesLabel: 'List OBD2 codes',
          answerGroups: ['A']
        },
        {
          value: 'Scratches',
          connotation: -1,
          chooseQuantity: true,
          maxQuantity: 10,
          answerGroups: ['A'],
          metaData: {
            takePhoto: {
              type: TakePhotoType.Damage,
              title: 'Scratches',
              description: 'Scratches'
            },
            artCode: {
              itemCode: 404,
              itemSubCode: 1,
              damageCode: 'ON',
              severityCode: 'UA'
            }
          }
        },
        {
          value: 'Dents',
          connotation: -1,
          chooseQuantity: true,
          answerGroups: ['B'],
          metaData: {
            takePhoto: {
              type: TakePhotoType.Damage,
              title: 'Dents',
              description: 'Dents'
            }
          }
        },
        {
          value: 'More Dents',
          connotation: -1,
          chooseQuantity: true,
          answerGroups: ['B'],
          metaData: {
            takePhoto: {
              type: TakePhotoType.Damage,
              title: 'more',
              description: 'Dents'
            }
          }
        }
      ]
    },
    {
      id: '12',
      guid: 'CHARGING-CABLE',
      text: 'Is the charging cable present?',
      buyerTranslation: 'Other issues',
      section: 'MECHANICAL',
      type: 'single',
      tags: ['engineType:electric'],
      answers: [
        {
          value: 'Yes',
          connotation: 0,
          metaData: {
            takePhoto: {
              type: TakePhotoType.Damage,
              title: 'cable',
              description: 'Front Hood Large Dent'
            }
          }
        },
        {
          value: 'No',
          connotation: -1
        }
      ]
    },
    {
      id: '260',
      guid: '631dfc0e-19d9-483b-8a18-2c610f4ab514',
      text: 'Front Bumper',
      section: 'Exterior',
      subSection: 'Body',
      buyerTranslation: 'Front Bumper',
      helpText: 'What kind of damage(s) does the bumper have?',
      type: 'multi',
      answers: [
        {
          value: 'Previous Paint Repair',
          connotation: 0,
          nestedQuestions: ['a3f838b0-1970-466d-a879-f3e3d0bfde9f'],
          answerGroups: ['S']
        },
        {
          value: 'Paint Chipped',
          connotation: -1,
          answerGroups: ['A'],
          metaData: {
            takePhoto: {
              type: TakePhotoType.Damage,
              title: 'Front Bumper Paint Chipped',
              description: 'Front Bumper Paint Chipped'
            },
            artCode: {
              itemCode: 21,
              itemSubCode: 0,
              damageCode: 'CH',
              severityCode: 'R8'
            }
          }
        },
        {
          value: 'Dents',
          connotation: 0,
          nestedQuestions: ['fcc50fb5-3754-4159-9983-a1343583b1da'],
          answerGroups: ['A', 'P']
        },
        {
          value: 'Scratches',
          connotation: -1,
          chooseQuantity: true,
          maxQuantity: 10,
          answerGroups: ['X'],
          metaData: {
            takePhoto: {
              type: TakePhotoType.Damage,
              title: 'Scratches',
              description: 'Scratches'
            },
            artCode: {
              itemCode: 404,
              itemSubCode: 1,
              damageCode: 'ON',
              severityCode: 'UA'
            }
          }
        }
      ]
    },
    {
      id: '262',
      guid: 'a3f838b0-1970-466d-a879-f3e3d0bfde9f',
      text: 'Front Bumper Paint Repair',
      section: 'Exterior',
      optional: true,
      subSection: 'Body',
      buyerTranslation: 'Front Bumper',
      helpText: 'Select the quality of the paint repair',
      type: 'single',
      answers: [
        {
          value: 'Acceptable Paint Repair',
          connotation: -1,
          announcements: ['AN0031'],
          metaData: {
            artCode: {
              itemCode: 21,
              itemSubCode: 0,
              damageCode: 'PR',
              severityCode: 'AC'
            }
          }
        },
        {
          value: 'Substandard Paint Repair',
          connotation: -1,
          announcements: ['AN0031'],
          metaData: {
            artCode: {
              itemCode: 21,
              itemSubCode: 0,
              damageCode: 'PR',
              severityCode: 'SR'
            }
          }
        }
      ]
    },
    {
      id: '284',
      guid: 'fcc50fb5-3754-4159-9983-a1343583b1da',
      text: 'Front Hood Dents',
      section: 'Exterior',
      optional: true,
      subSection: 'Body',
      buyerTranslation: 'Front Hood',
      helpText: 'Select the severity of the dent(s)',
      type: 'single',
      answers: [
        {
          value: 'Small Dent',
          connotation: -1,
          metaData: {
            takePhoto: {
              type: TakePhotoType.Damage,
              title: 'Front Hood Large Dent',
              description: 'Front Hood Large Dent'
            },
            artCode: {
              itemCode: 50,
              itemSubCode: 0,
              damageCode: 'DNP',
              severityCode: 'GE'
            }
          }
        },
        {
          value: 'Large Dent',
          connotation: -1,
          metaData: {
            takePhoto: {
              type: TakePhotoType.Damage,
              title: 'Front Hood Large Dent',
              description: 'Front Hood Large Dent'
            },
            artCode: {
              itemCode: 50,
              itemSubCode: 0,
              damageCode: 'DNP',
              severityCode: 'G2'
            }
          }
        }
      ]
    }
  ],
  autoTag: true
};
