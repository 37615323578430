import React from 'react';
import styled from 'styled-components';

const SectionHeading = styled.label`
  background-color: #d3d3d3;
  padding: 2px;
  border-bottom: 0.5px solid gray;
  margin-bottom: 5px;
`;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid gray;
  margin-bottom: 5px;
`;

const SectionBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2px;
  margin-bottom: 5px;
`;

export const Section = (props: { heading: string; children: React.ReactNode }) => {
  return (
    <SectionContainer>
      <SectionHeading>{props.heading}</SectionHeading>
      <SectionBody>{props.children}</SectionBody>
    </SectionContainer>
  );
};
