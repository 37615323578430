/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { AppState } from '../models/AppState';
import { debugQuestionnaire } from './debugQuestionnaire';
import { ClientAnnouncerSource, CLIENT_ANNOUNCER_SOURCES, AppConfiguration } from '../models/AppConfiguration';
import { EnvironmentName, ENVIRONMENT_NAMES } from '../models/EnvironmentName';
import { Questionaire } from '../models/QEQuestion';
import { Announcement } from '../models/Announcement';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-github';
import { QuestionsAndAnnouncements as QuestionsAndAnnouncementsType } from '../index';
import { fetchFormsList } from '../api/fetchFormsList';
import { setupAxios } from '../helpers/httpRequestHelper';
import { fetchQuestionnaire } from '../api/fetchQuestionnaire';
import { Section } from './Section';
import { QEForm } from '../models/QEForm';

declare const QuestionsAndAnnouncements: typeof QuestionsAndAnnouncementsType;

const LOCAL_STORAGE_KEY = 'QNA-DEMO-STATE';

type QuestionnaireMode = 'Existing' | 'Debug' | 'Upload';
const filterFormat = (filter: string) => {
  return filter
    .replace(/ /g, ',')
    .split(',')
    .map(t => t.trim())
    .filter(t => t);
};
function useLocalStorageState<TState>(uniqueKey: string, defaultValue: TState) {
  const appState = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) || '{}');
  const [value, setter] = useState<TState>(uniqueKey in appState ? appState[uniqueKey] : defaultValue);
  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify({ ...appState, [uniqueKey]: value }));
  }, [value]);
  return [value, setter] as const;
}

const DemoApp = () => {
  const [renderErrors, setRenderErrors] = useState<string[]>([]);
  const [appState, setAppState] = useLocalStorageState<AppState | undefined>('appState', undefined);
  const [announcerSource, setAnnouncerSource] = useLocalStorageState<ClientAnnouncerSource>(
    'announcerSource',
    'seller'
  );
  const [formsList, setFormsList] = useLocalStorageState<QEForm[] | undefined>('formsList', undefined);
  const [questionnaireHref, setQuestionnaireHref] = useLocalStorageState<string | undefined>(
    'questionnaireHref',
    undefined
  );
  const [environment, setEnvironment] = useLocalStorageState<EnvironmentName>('environment', 'integration1');
  const [readonly, setReadonly] = useLocalStorageState('readonly', false);
  const [filterByTags, setFilterByTags] = useLocalStorageState('filterByTags', false);
  const [filterByUserType, setFilterByUserType] = useLocalStorageState('filterByUserType', false);
  const [tags, setTags] = useLocalStorageState('tags', '');
  const [userType, setUserType] = useLocalStorageState('userType', '');
  const [questionnaireMode, setQuestionnaireMode] = useLocalStorageState<QuestionnaireMode>(
    'questionnaireMode',
    'Existing'
  );
  const [lastDebugQuestionnaireUrl, setLastDebugQuestionnaireUrl] = useLocalStorageState(
    'lastDebugQuestionnaireUrl',
    ''
  );
  const [debugFormRaw, setDebugFormRaw] = useLocalStorageState(
    'debugFormRaw',
    JSON.stringify(debugQuestionnaire, null, 2)
  );
  const [useDebugDictionary, setUseDebugDictionary] = useLocalStorageState('useDebugDictionary', false);
  const [debugDictionaryRaw, setDebugDictionaryRaw] = useLocalStorageState(
    'debugDictionaryRaw',
    JSON.stringify([{ code: 'ANFOO', text: 'Foo Announcement' }], null, 2)
  );
  const [sceneName, setSceneName] = useLocalStorageState<'questions' | 'announcements'>('sceneName', 'questions');
  const loadFormsList = async () => {
    const formsListResponse = await fetchFormsList();
    setFormsList([...formsListResponse]);
  };

  const [unitHref, setUnitHref] = useLocalStorageState('unitHref', '');

  const [showAlert, setShowAlert] = useState(false);

  const [alertSettings, setAlertSettings] = useState<
    | { alertType: 'ineligible'; questionGuid: string; answerValue: string }
    | {
        alertType: 'editImages';
        questionGuid: string;
        newAnswer: string;
        selectedAnswers: string[];
      }
    | {
        alertType: 'editImages';
        newAnswer: string;
        questionGuid: string;
        parentQuestion?: string;
        parentAnswer?: string;
        photosToRemove: {
          selectedAnswers: string[];
          questionGuid: string;
        }[];
      }
    | undefined
  >();

  const errorMessages = [];
  let debugForm: Questionaire | undefined;
  try {
    debugForm = JSON.parse(debugFormRaw);
  } catch (e: any) {
    console.log('debug form', debugFormRaw);
    console.log(`Error parsing form: ${e.message}`);
    errorMessages.push(`Error parsing form: ${e.message}`);
  }

  let debugDictionary: Announcement[] | undefined;
  try {
    debugDictionary = JSON.parse(debugDictionaryRaw);
  } catch (e: any) {
    errorMessages.push(`Error parsing dictionary: ${e.message}`);
  }

  let appConfig: AppConfiguration | undefined;
  if (errorMessages.length === 0) {
    appConfig = {
      colors: { headerTextColor: '#6e2262' },
      clientApplicationName: 'Debug App',
      environment: environment,
      announcerSource: announcerSource,
      initialState: { questionResponses: [], announcements: [] },
      onStateChange: setAppState,
      rootElementId: 'demoApp',
      debug: true,
      readonly,
      tags: filterByTags ? filterFormat(tags) : undefined,
      userType: filterByUserType ? filterFormat(userType) : undefined,
      debugQuestionnaire: questionnaireMode === 'Debug' ? debugForm : undefined,
      debugDictionary: useDebugDictionary ? debugDictionary : undefined,
      questionnaireHref: questionnaireHref,
      questionMessages: [
        {
          tags: ['999999'],
          message: 'Hey this is a message for a question'
        },
        {
          tags: ['123456'],
          message:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
        }
      ],
      unitHref: unitHref,
      inlineImaging: true
    };
    setupAxios(appConfig);
  }

  useEffect(() => {
    loadFormsList();
  }, [environment]);

  useEffect(() => {
    if (appConfig) {
      try {
        setRenderErrors([]);
        const finalConfig: AppConfiguration = {
          ...appConfig,
          initialState: {
            questionResponses: appState?.questionResponses || [],
            announcements: appState?.announcements || []
          }
        };
        console.log('Rendering with config:', finalConfig);
        QuestionsAndAnnouncements[sceneName === 'questions' ? 'renderQuestions' : 'renderAnnouncements'](finalConfig);
      } catch (e: any) {
        setRenderErrors([e.message]);
      }
    }
  }, [sceneName, JSON.stringify(appConfig)]);

  useEffect(() => {
    const promptForMetadata = appState?.metaData?.find(
      responseMetaData => responseMetaData.metaData.dsi?.dsiIneligible === true
    );
    if (promptForMetadata) {
      // Uncomment this to test alerts
      // setShowAlert(true);
      setAlertSettings({
        alertType: 'ineligible',
        questionGuid: promptForMetadata.guid,
        answerValue: promptForMetadata.value
      });
    }

    if (appState?.alertMessageDetails) {
      setAlertSettings({ alertType: 'editImages', ...appState.alertMessageDetails });
    }

    if (appState?.takePhotoDetails?.questionGuid && appState.takePhotoDetails.answer) {
      setTimeout(() => {
        window.postMessage(
          JSON.stringify({
            type: 'photoCaptured',
            questionGuid: appState.takePhotoDetails?.questionGuid || '',
            answer: appState.takePhotoDetails?.answer || ''
          }),
          window.origin
        );
      }, 5000);
    }
  }, [appState]);

  const handleReset = () => {
    localStorage.removeItem(LOCAL_STORAGE_KEY);
    window.location.reload();
  };

  const handleResetAppState = () => {
    setAppState(undefined);
  };

  const loadFormIntoDebugEditor = async (url: string) => {
    try {
      const newForm = await fetchQuestionnaire(url);
      setQuestionnaireMode('Debug');
      setDebugFormRaw(JSON.stringify(newForm, null, 2));
    } catch (error: any) {
      console.error('error loading form', { url, error });
      alert(`Problem loading form (see console for details):\n${error.message}`);
    }
  };

  return (
    <Body>
      <h1>Questions And Announcements Demo</h1>
      <Container>
        {showAlert && alertSettings?.alertType === 'ineligible' && (
          <CustomAlert>
            <AlertContainer>
              <InnerContainer>
                <HeaderText data-test-id="header-text">Verification Required</HeaderText>
                <SubHeaderText>{`You selected ${alertSettings.answerValue}. Is this accurate?`}</SubHeaderText>

                <ButtonContainer>
                  <ConfirmButton
                    onClick={() => {
                      window.postMessage(
                        JSON.stringify({
                          type: 'removeAnswer',
                          questionGuid: alertSettings.questionGuid,
                          answerValue: alertSettings.answerValue
                        }),
                        window.origin
                      );
                      setShowAlert(false);
                    }}
                  >
                    No, Go Back
                  </ConfirmButton>
                  <ConfirmButton
                    onClick={() => {
                      setShowAlert(false);
                      alert('ineligible');
                    }}
                  >
                    Yes, Continue
                  </ConfirmButton>
                </ButtonContainer>
              </InnerContainer>
            </AlertContainer>
          </CustomAlert>
        )}
        {alertSettings?.alertType === 'editImages' && (
          <CustomAlert>
            <AlertContainer>
              <InnerContainer>
                <HeaderText data-test-id="header-text">Edit images</HeaderText>
                <SubHeaderText>
                  To edit this question, images will be removed and need to be taken again. Do you want to proceed?
                </SubHeaderText>
                <ButtonContainer>
                  <ConfirmButton data-test-id="bottom-close-btn" onClick={() => setAlertSettings(undefined)}>
                    CANCEL
                  </ConfirmButton>
                  <ConfirmButton
                    data-test-id="bottom-close-btn"
                    onClick={() => {
                      window.postMessage(
                        JSON.stringify({
                          type: 'removeDamageAnswer',
                          messageDetails: appState?.alertMessageDetails
                        }),
                        window.origin
                      );
                      setAlertSettings(undefined);
                    }}
                  >
                    PROCEED
                  </ConfirmButton>
                </ButtonContainer>
              </InnerContainer>
            </AlertContainer>
          </CustomAlert>
        )}
        <AppContainer>
          {[...errorMessages, ...renderErrors].map(msg => (
            <ErrorMessage key={msg}>{msg}</ErrorMessage>
          ))}
          <div id="demoApp" />
        </AppContainer>
        <ConfigContainer>
          <h3>Configuration</h3>
          <Section heading="Environment">
            <select value={environment} onChange={e => setEnvironment(e.target.value as any)}>
              {ENVIRONMENT_NAMES.map(value => (
                <option key={value} value={value}>
                  {value}
                </option>
              ))}
            </select>
          </Section>
          <Section heading="Announcer Source">
            <select value={announcerSource} onChange={e => setAnnouncerSource(e.target.value as any)}>
              {CLIENT_ANNOUNCER_SOURCES.map(value => (
                <option key={value} value={value}>
                  {' '}
                  {value}{' '}
                </option>
              ))}
            </select>
          </Section>
          <Section heading="Scene">
            <select value={sceneName} onChange={e => setSceneName(e.target.value as any)}>
              {['questions', 'announcements'].map(value => (
                <option key={value} value={value}>
                  {value}
                </option>
              ))}
            </select>
            <label onClick={() => setReadonly(!readonly)}>
              <input type="checkbox" checked={readonly} />
              {' Readonly'}
            </label>
          </Section>
          <Section heading="Tags">
            <label onClick={() => setFilterByTags(!filterByTags)}>
              <input type="checkbox" checked={filterByTags} />
              {' Filter by tags'}
            </label>
            <input
              value={tags}
              disabled={!filterByTags}
              placeholder="eg: engineType:electric, roof:sunroof, vehicleType:truck"
              onChange={e => setTags(e.target.value.replace(/^[ ,]+/, ''))}
            />

            <label onClick={() => setFilterByUserType(!filterByUserType)}>
              <input type="checkbox" checked={filterByUserType} />
              {' Filter by userType'}
            </label>
            <input
              value={userType}
              disabled={!filterByUserType}
              placeholder="eg: dealer, demo"
              onChange={e => setUserType(e.target.value.replace(/^[ ,]+/, ''))}
            />
            <label>unitHref</label>
            <input value={unitHref} onChange={e => setUnitHref(e.target.value)} />
          </Section>
          <Section heading="Form">
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <label onClick={() => setQuestionnaireMode('Existing')}>
                <input type="radio" checked={questionnaireMode === 'Existing'} />
                {' Existing Questionnaire'}
              </label>
              <label onClick={() => setQuestionnaireMode('Debug')}>
                <input type="radio" checked={questionnaireMode === 'Debug'} />
                {' Debug Questionnaire'}
              </label>
            </div>
            {questionnaireMode === 'Existing' && (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <select
                  value={questionnaireHref || ''}
                  onChange={e => setQuestionnaireHref(e.target.value || undefined)}
                >
                  <option value="">Unspecified (let QNA choose your questionnaire)</option>
                  {formsList?.flatMap(value => [
                    <option key={`${value.name}-late`} value={value.href}>
                      Forms API - {value.name}/latest - {value.href.split('/').slice(-1)[0]}
                    </option>,
                    <option key={`${value.name}-test`} value={value.testHref}>
                      Forms API - {value.name}/test - {value.testHref.split('/').slice(-1)[0]}
                    </option>
                  ])}
                </select>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Button onClick={() => questionnaireHref && loadFormIntoDebugEditor(questionnaireHref)}>
                    Edit Selected Questionnaire in Debug
                  </Button>
                </div>
              </div>
            )}
            {questionnaireMode === 'Debug' && (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Button
                  onClick={async () => {
                    const url = prompt('Enter the URL to the form', lastDebugQuestionnaireUrl);
                    if (url) {
                      setLastDebugQuestionnaireUrl(url);
                      loadFormIntoDebugEditor(url);
                    }
                  }}
                >
                  Load Questionnaire From URL
                </Button>
                <Button onClick={() => setDebugFormRaw(JSON.stringify(debugQuestionnaire, null, 2))}>
                  Reset Debug Questionnaire
                </Button>
                <JsonEditor value={debugFormRaw} onChange={setDebugFormRaw} />
              </div>
            )}
          </Section>
          <Section heading="Announcement Dictionary">
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <label onClick={() => setUseDebugDictionary(false)}>
                <input type="radio" checked={!useDebugDictionary} />
                {' Existing Dictionary'}
              </label>
              <label onClick={() => setUseDebugDictionary(true)}>
                <input type="radio" checked={useDebugDictionary} />
                {' Debug Dictionary'}
              </label>
            </div>
            {useDebugDictionary && <JsonEditor value={debugDictionaryRaw} onChange={setDebugDictionaryRaw} />}
          </Section>
          <Section heading="App State">
            <Button onClick={handleResetAppState}>Reset AppState</Button>
            <pre>{appState && JSON.stringify(appState, null, 2)}</pre>
          </Section>
          <Section heading="Go Nuclear">
            <Button onClick={handleReset}>Reset *All* Configuration</Button>
          </Section>
        </ConfigContainer>
      </Container>
    </Body>
  );
};

const Body = styled.div`
  font-family: roboto, helvetica, sans-serif;
`;
const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

const ConfigContainer = styled.div`
  display: flex;
  padding: 2px;
  flex-direction: column;
  margin-left: 5px;
`;

const JsonEditor = styled(AceEditor).attrs({
  mode: 'json',
  tabSize: 2,
  width: '100%',
  wrapEnabled: true
})``;

const AppContainer = styled.div`
  border: 1px solid lightgray;
  width: 400px;
  padding: 5px;
`;

const ErrorMessage = styled.pre`
  white-space: pre-wrap;
`;

const Button = styled.button`
  background-color: white;
  border: 1px solid black;
  border-radius: 3px;
  margin-top: 2px;
  margin-bottom: 2px;
  padding: 5px;
  &:disabled {
    background-color: #c3c3c3;
    color: gray;
  }
  &:hover :not(:disabled) {
    background-color: lightgray;
  }
`;

const CustomAlert = styled.div`
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
`;

const AlertContainer = styled.div`
  position: absolute;
  width: 280px;
  box-shadow: rgba(0, 0, 0, 0.5) 5px 5px 10px;
  background-color: white;
  border: 1px;
  padding: 10px 10px 20px 10px;
  border-radius: 15px;
  left: 60px;
  height: 195px;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-content: center;
`;

const ButtonContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  flex: 1;
  border-top: 1px solid black;
  align-content: center;
  height: 50px;
`;

const ConfirmButton = styled.button`
  width: calc(100% - 60px);
  margin: 20px 5px 10px 10px;
  font-weight: 700;
`;

const HeaderText = styled.h1`
  font-family: Poppins;
  color: #000000;
  margin: 5px;
  margin-top: 0px;
  font-size: 20px;
  display: flex;
  justify-content: center;
`;

const SubHeaderText = styled.h3`
  font-size: 14px;
  margin: 0 auto;
  color: #000000;
  line-height: 1.2;
  white-space: normal;
  width: calc(100% - 35px);
  display: flex;
  text-align: center;
`;

ReactDOM.render(<DemoApp />, document.getElementById('root'));
